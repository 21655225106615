import { Box, Button, Divider, Heading, VStack, Wrap } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Garment as GarmentIcon, Model as ModelIcon, Outfit as OutfitIcon } from '../assets/icons';
import MultipleImages from '../assets/icons/MultipleImages';
import AnnouncementBanner from '../components/AnnouncementBanner';
import LinkTile from '../components/LinkTile';
import Settings from '../components/Settings';
import { StatCard } from '../components/stats';
import { useGetClientCountQuery, useGetGroupCountQuery } from '../services/api/api-client';
import { getClient, getConfig, getExperience, getGroup, getGroupConfig } from '../services/store/slices/sessionSlice';
import { useAppSelector } from '../services/store/store';
import { ERROR_LOCALES, HOME_LOCALES } from '../utils/constants';
import { supportMailTo } from '../utils/help-helpers';

const Home = () => {
    const { t } = useTranslation([HOME_LOCALES]);

    const currentClient = useAppSelector((state) => getClient(state));
    const config = useAppSelector((state) => getConfig(state));
    const selectedExperience = useAppSelector((state) => getExperience(state));
    const groupConfigSlice = useAppSelector((state) => getGroupConfig(state));
    const currentGroup = useAppSelector((state) => getGroup(state));

    const isAll = currentGroup?.groupType === 'group' && currentClient?.id === '';

    const { data: clientCount, isFetching: clientCountIsFetching } = useGetClientCountQuery({
        clientId: currentClient ? currentClient.id : '',
        experienceId: selectedExperience ? selectedExperience.id : undefined,
    }, { skip: isAll === true || !currentClient });

    const { data: groupCount, isFetching: groupCountIsFetching } = useGetGroupCountQuery({
        experienceId: selectedExperience ? selectedExperience.id : undefined,
        groupId: groupConfigSlice?.group_id || '',
    }, { skip: currentGroup?.groupType !== 'group' });

    const [isAnnouncementOpen, setIsAnnouncementOpen] = useState<boolean>(false);

    // If there is no current client and the requests are finished loading we show the client error
    if (!currentClient) {
        return (<Box paddingBottom={5}>{t('no_current_client', { ns: ERROR_LOCALES })}</Box>);
    }

    return (
        <VStack boxSize="full" spacing={8} sx={{ '&>div': { w: 'full' } }}>
            <VStack spacing={4}>
                <Heading color="gray.700" fontSize="2xl">{t('title')}</Heading>
                <Button
                    onClick={supportMailTo}
                    variant="solid"
                >
                    {t('help_button_label')}
                </Button>
            </VStack>
            <AnnouncementBanner
                description={t('announcement.description')}
                isOpen={isAnnouncementOpen}
                onClose={() => setIsAnnouncementOpen(false)}
                title={t('announcement.title')}
            />
            <VStack boxSize="full" spacing={4}>
                {
                    config?.platform_enable_counters === true && (
                        <Wrap justify="center" spacing={4} w="full">
                            <StatCard
                                Icon={ModelIcon}
                                label={t('stats.active_models')}
                                loading={clientCountIsFetching && groupCountIsFetching}
                                value={clientCount?.active_models || groupCount?.active_models || 0}
                                w={60}
                            />
                            <StatCard
                                Icon={GarmentIcon}
                                label={t('stats.active_garments')}
                                loading={clientCountIsFetching && groupCountIsFetching}
                                value={clientCount?.active_garments || groupCount?.active_garments || 0}
                                w={60}
                            />
                            <StatCard
                                Icon={OutfitIcon}
                                label={t('stats.potential_outfits')}
                                loading={clientCountIsFetching && groupCountIsFetching}
                                value={clientCount?.active_looks || groupCount?.active_looks || 0}
                                w={60}
                            />
                            <StatCard
                                Icon={MultipleImages}
                                label={t('stats.number_of_images')}
                                loading={clientCountIsFetching && groupCountIsFetching}
                                value={clientCount?.active_images || groupCount?.active_images || 0}
                                w={60}
                            />
                        </Wrap>
                    )
                }

                {
                    !isAll && (
                        <Wrap justify="center" pb='16px !important' spacing={4} w="full">
                            {
                                config?.platform_enable_dashboard
                                && <LinkTile
                                    description={t('page_bloc.dashboard.description')}
                                    illustrationSrc='/assets/illustrations/dashboard.svg'
                                    label={t('page_bloc.dashboard.label')}
                                    link="/dashboard"
                                />
                            }

                            <LinkTile
                                description={t('page_bloc.garments.description')}
                                illustrationSrc='/assets/illustrations/garment.svg'
                                label={t('page_bloc.garments.label')}
                                link="/garment"
                            />
                            <LinkTile
                                description={t('page_bloc.models.description')}
                                illustrationSrc='/assets/illustrations/model.svg'
                                label={t('page_bloc.models.label')}
                                link="/model"
                            />
                            {
                                config?.platform_enable_outfit && (
                                    <LinkTile
                                        description={t('page_bloc.outfits.description')}
                                        illustrationSrc='/assets/illustrations/outfit.svg'
                                        label={t('page_bloc.outfits.label')}
                                        link="/outfit"
                                    />
                                )
                            }
                        </Wrap>
                    )
                }
                {
                    (config?.platform_enable_mix_and_match !== undefined || config?.platform_enable_switch_model !== undefined)
                    && <VStack alignItems="flex-start" pb={4} spacing={4} w="100%">
                        <Divider />
                        <Settings />
                    </VStack>
                }

            </VStack>
        </VStack>
    );
};

export default Home;
