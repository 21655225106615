import { Heading, HStack, Spinner, Switch, Text, useToast, VStack } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { usePutClientConfigsMutation } from '../services/api/api-client';
import { getClient, getConfig, setConfig } from '../services/store/slices/sessionSlice';
import { useAppSelector } from '../services/store/store';
import { ClientConfig } from '../types/api-types';
import { COMMON_LOCALES, ERROR_LOCALES, HOME_LOCALES } from '../utils/constants';
import CustomModal from './Modal';

// ---- CONST LISTING EXPERIENCE KEY STRINGS ----
const EXPERIENCES = ['mix_and_match', 'switch_model'] as const;

export default function Settings() {
    const dispatch = useDispatch();
    const { t } = useTranslation([HOME_LOCALES, COMMON_LOCALES, ERROR_LOCALES]);
    const toast = useToast();

    const config = useAppSelector((state) => getConfig(state));
    const currentClient = useAppSelector((state) => getClient(state));

    const [putConfigs, { isLoading }] = usePutClientConfigsMutation();

    // ---- Current displaySetting being modified ----
    const [displaySetting, setDisplaySetting] = useState<string | undefined>();
    // ---- Local state of the display setting values ----
    const [displaySettingValues, setDisplaySettingValues] = useState<{ [key: string]: boolean }>({});

    // ---- Boolean to know if we are activating or deactivating a setting ----
    const isDisplaySettingActivating = useMemo(() => {
        const currentDisplaySettingValue = Object.keys(displaySettingValues).find((valueKey) => valueKey === displaySetting);

        if (!currentDisplaySettingValue) {
            return false;
        }

        return !displaySettingValues[currentDisplaySettingValue];
    }, [displaySetting, displaySettingValues]);

    const actionTranslationKey = useMemo(() => (isDisplaySettingActivating ? 'activate' : 'deactivate'), [isDisplaySettingActivating]);

    const experienceName = useMemo(() => (displaySetting ? t(displaySetting, { ns: COMMON_LOCALES }) : ''), [displaySetting]);

    const displaySettingModalTitle = useMemo(() => t(
        `display_settings.modal.${actionTranslationKey}.title`,
        { experienceName, ns: HOME_LOCALES },
    ),
    [experienceName, actionTranslationKey, t]);

    const displaySettingModalConfirm = useMemo(() => t(
        `display_settings.modal.${actionTranslationKey}.confirm`,
        { ns: HOME_LOCALES },
    ),
    [actionTranslationKey, t]);

    const handleDisplaySettingChange = (newDisplaySetting: string) => {
        setDisplaySetting(newDisplaySetting);
    };

    const handleConfirmDisplaySetting = () => {
        // ---- We need the displaySetting and currentClient ----
        if (!displaySetting || !currentClient) {
            return;
        }

        // ---- API Call ----
        putConfigs(
            { clientId: currentClient.id, payload: { name: displaySetting, value: !displaySettingValues[displaySetting] } },
        ).unwrap().then(() => {
            // ---- Update local states ----
            setDisplaySettingValues(
                {
                    ...displaySettingValues,
                    [displaySetting]: !displaySettingValues[displaySetting],
                },
            );

            // ---- Update the config ----
            const newConfig: ClientConfig = JSON.parse(JSON.stringify(config));
            newConfig[displaySetting as `platform_enable_${string}`] = !displaySettingValues[displaySetting];
            dispatch(setConfig(newConfig));

            // ---- Reset displaySetting value to close the modal ----
            setDisplaySetting(undefined);

            // ---- Show success toast ----
            const localActionTranslationKey = !displaySettingValues[displaySetting] ? 'activate' : 'deactivate';
            toast({
                description: t(
                    `display_settings.modal.${localActionTranslationKey}.toast.description`,
                    { experienceName, ns: HOME_LOCALES },
                ),
                isClosable: true,
                status: 'success',
                title: t(
                    `display_settings.modal.${localActionTranslationKey}.toast.title`,
                    { experienceName, ns: HOME_LOCALES },
                ),
            });
        }).catch(() => {
            // ---- Show Error toast ----
            toast({
                isClosable: true,
                status: 'error',
                title: t(
                    'submit',
                    { ns: ERROR_LOCALES },
                ),
            });
        });
    };

    useEffect(() => {
        // ---- Do nothing if we don't have a config yet ----
        if (!config) {
            return;
        }

        // ---- Var init ----
        const configDisplaySettingValues: { [key: string]: boolean } = {};

        // ---- For each experience keys we get the new value from the config -----
        EXPERIENCES.forEach((experienceKey) => {
            const configFullKey = `platform_enable_${experienceKey}` as const;

            if (config[configFullKey] === undefined) {
                return;
            }

            configDisplaySettingValues[configFullKey] = config[configFullKey] as boolean;
        });

        // ---- Update state ----
        setDisplaySettingValues(configDisplaySettingValues);
    }, [config]);

    // ---- Hide Settings if we don't have at least 1 setting ----
    if (Object.keys(displaySettingValues).length === 0) {
        return null;
    }

    return (
        <VStack alignItems="flex-start">
            <CustomModal
                cancelButtonLabel={t('cancel', { ns: COMMON_LOCALES })}
                confirmButtonLabel={displaySettingModalConfirm}
                isOpen={!!displaySetting}
                onCancel={() => setDisplaySetting(undefined)}
                onClose={() => setDisplaySetting(undefined)}
                onConfirm={handleConfirmDisplaySetting}
                title={displaySettingModalTitle}
            >
                <VStack>
                    {
                        displaySetting && <Text>{t(
                            `display_settings.modal.${actionTranslationKey}.description`,
                            { experienceName, ns: HOME_LOCALES },
                        )}</Text>
                    }
                    {
                        isLoading && <Spinner />
                    }
                </VStack>

            </CustomModal>
            <VStack alignItems="flex-start" spacing={1} w="100%">
                <Heading color="black" size="md">{t('display_settings.title', { ns: HOME_LOCALES })}</Heading>
                <Text color="gray">{t('display_settings.subtitle', { ns: HOME_LOCALES })}</Text>
            </VStack>

            <VStack>
                {
                    Object.keys(displaySettingValues).map((valueKey) => <HStack
                        justifyContent="space-between"
                        key={valueKey}
                        spacing={8}
                        w="100%"
                    >
                        <Text fontWeight="bold">{t(valueKey, { ns: COMMON_LOCALES })}</Text>
                        <Switch isChecked={displaySettingValues[valueKey]} onChange={() => handleDisplaySettingChange(valueKey)} />
                    </HStack>)
                }
            </VStack>
        </VStack>
    );
}
